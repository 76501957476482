import React from "react";
import FirebaseProvider from "./firebase.config";
import "./src/styles/global.css";
import "mapbox-gl/dist/mapbox-gl.css";
import "react-datepicker/dist/react-datepicker.css";

// kendo theme
//import "@progress/kendo-theme-default/dist/all.css";
import "./src/styles/kendo-spike.scss";

// Wraps every page in a component
export const wrapPageElement = ({ element, props }) => {
  return <FirebaseProvider>{element}</FirebaseProvider>;
};
