module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-gatsby-cloud/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Spike Onsite Solutions","short_name":"SpikeSOS","start_url":"/","display":"standalone","icon":"src/images/spikesos_manifest.jpeg","icons":[{"src":"/images/spike-transparentlogo.png","type":"image/png","sizes":"512x512"},{"src":"/images/spikesos_manifest.jpeg","type":"images/jpeg","sizes":"192x192"}],"background_color":"#ffe502","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"03a23aead3a4899d13fc48454c7bedca"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
