import React from "react";
import { getAuth } from "firebase/auth"; // Firebase v9+
import {
  FirebaseAppProvider,
  FirestoreProvider,
  AuthProvider,
  useFirebaseApp,
  FunctionsProvider,
  StorageProvider,
} from "reactfire";
import { getFirestore } from "@firebase/firestore";
import { getFunctions } from "@firebase/functions";
import { getStorage } from "@firebase/storage";

const firebaseConfig = {
  apiKey: process.env.GATSBY_apiKey,
  authDomain: process.env.GATSBY_authDomain,
  databaseURL: process.env.GATSBY_databaseURL,
  projectId: process.env.GATSBY_projectId,
  storageBucket: process.env.GATSBY_storageBucket,
  messagingSenderId: process.env.GATSBY_messagingSenderId,
  appId: process.env.GATSBY_appId,
  measurementId: process.env.GATSBY_measurementId,
};

function FirebaseProvider({ children }) {
  return (
    <FirebaseAppProvider firebaseConfig={firebaseConfig}>
      <FirebaseComponents>{children}</FirebaseComponents>
    </FirebaseAppProvider>
  );
}

function FirebaseComponents({ children }) {
  const app = useFirebaseApp(); // a parent component contains a `FirebaseAppProvider`

  // initialize Database and Auth with the normal Firebase SDK functions
  const firestore = getFirestore(app);
  const auth = getAuth(app);
  const functions = getFunctions(app);
  const storage = getStorage(app);

  // any child components will be able to use `useUser`, `useDatabaseObjectData`, etc
  return (
    <AuthProvider sdk={auth}>
      <StorageProvider sdk={storage}>
        <FunctionsProvider sdk={functions}>
          <FirestoreProvider sdk={firestore}>{children}</FirestoreProvider>
        </FunctionsProvider>
      </StorageProvider>
    </AuthProvider>
  );
}

export default FirebaseProvider;
