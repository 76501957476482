exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-admin-js": () => import("./../../../src/pages/admin/[...].js" /* webpackChunkName: "component---src-pages-admin-js" */),
  "component---src-pages-app-js": () => import("./../../../src/pages/app/[...].js" /* webpackChunkName: "component---src-pages-app-js" */),
  "component---src-pages-customer-js": () => import("./../../../src/pages/customer/[...].js" /* webpackChunkName: "component---src-pages-customer-js" */),
  "component---src-pages-dispatch-js": () => import("./../../../src/pages/dispatch/[...].js" /* webpackChunkName: "component---src-pages-dispatch-js" */),
  "component---src-pages-driver-js": () => import("./../../../src/pages/driver/[...].js" /* webpackChunkName: "component---src-pages-driver-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

